<template>
    <div v-if="newChart">
        <apexchart width="100%" :height="height" type="bar" :options="newOptions" :series="newSeries" ref="chart"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: ['rekap','newChart'],
    // watch:{
    //     newChart:function(newV, oldV){
    //         if(oldV != newV){
    //             this.$refs.chart.refresh()
    //         }
    //     }
    // },
    computed: {
        height() {
            let x = this.rekap.length;
            if (x == 1 && x <= 5) {
                return 300;
            } else if (x > 5 && x < 10) {
                return 750;
            } else if (x > 10 && x < 15) {
                return 1000;
            } else {
                return 2500;
            }
        },
        newOptions() {
            let x = [];
            for (let i = 0; i < this.rekap.length; i++) {
                x.push(this.rekap[i].nama_prodi);
            }

            return {
                chart: {
                    id: 'Chart Tagihan',
                },
                xaxis: {
                    categories: x,
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                        },
                    },
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['black'],
                },
                legend: {
                    position: 'top',
                },
                dataLabels: {
                    enabled: false,
                    offsetX: 0,
                    style: {
                        fontSize: '12px',
                        colors: ['black'],
                    },
                    formatter: function (val) {
                        if (val >= 1) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                        }
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                title: {
                    text: '',
                    align: 'right',
                    offsetY: 0,
                    floating: true,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#263238',
                    },
                },
            };
        },
        newSeries() {
            let x = [];
            let y = [];
            let z = [];

            for (let i = 0; i < this.rekap.length; i++) {
                x.push(this.rekap[i].tagihan - this.rekap[i].terbayar);
                y.push(this.rekap[i].terbayar);
                z.push(this.rekap[i].tagihan);
            }

            return [
                {
                    name: 'Piutang',
                    data: x,
                },
                {
                    name: 'Terbayar',
                    data: y,
                },
                {
                    name: 'Total Tagihan',
                    data: z,
                },
            ];
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'Chart Tagihan',
                },
                xaxis: {
                    categories: [],
                },
            },
            series: [
                {
                    name: 'Belum Dibayar',
                    data: [],
                },
                {
                    name: 'Piutang',
                    data: [],
                },
                {
                    name: 'Total Tagihan',
                    data: [],
                },
            ],
        };
    },
};
</script>