<template>
    <div class="grid">
        <div class="col">
            <div class="grid">
                <div class="col">
                    <h3 v-if="isMobile">DASHBOARD SIBILLING</h3>
                    <h1 v-else>DASHBOARD SIBILLING</h1>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <div class="grid p-fluid">
                            <div class="col">
                                <div class="card">
                                    <div class="grid p-fluid">
                                        <div class="col-6">
                                            <span class="p-float-label mt-4">
                                                <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                                <label for="prodi_id">Prodi</label>
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <span class="p-float-label mt-4">
                                                <Dropdown dataKey="value" filter id="tahun_kb" v-model.lazy="data.tahun_kb" :options="tahun" optionLabel="text" optionValue="value" />
                                                <label for="tahun">Tahun</label>
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <span class="p-float-label mt-4">
                                                <Dropdown dataKey="value" filter id="semester" v-model.lazy="data.semester" :options="semester" optionLabel="text" optionValue="value" />
                                                <label for="semester">Semester</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="grid mt-4">
                                        <div class="col-6 text-right">
                                            <div class="grid p-fluid">
                                                <div class="col-4">
                                                    <Button class="p-button p-button-success" block label="Search" @click="getNewRekap()" :disabled="!newChart" />
                                                </div>
                                                <div class="col-4">
                                                    <Button class="p-button p-button-primary" label="Reset" @click="reset()" :disabled="!newChart" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid mt-4" align-items: center>
                            <div class="col-6">
                                <div class="grid" style="background-color: #0dcaf0; border-radius: 1px">
                                    <div class="col-6">
                                        <h4>TOTAL PIUTANG</h4>
                                    </div>
                                    <div class="col-4" align="right">
                                        <p>{{ $formats(piutang).format() }}</p>
                                    </div>
                                    <div class="col-2" align="right">
                                        <p>{{ piutang ? ((piutang / totals) * 100).toFixed(2) : 0 }} %</p>
                                    </div>
                                </div>
                                <div class="grid" style="background-color: rgba(0, 227, 150, 0.85)">
                                    <div class="col-6">
                                        <h4>TOTAL TERBAYAR</h4>
                                    </div>
                                    <div class="col-4" align="right">
                                        <p>{{ $formats(terbayar).format() }}</p>
                                    </div>
                                    <div class="col-2" align="right">
                                        <p>{{ terbayar ? ((terbayar / totals) * 100).toFixed(2) : 0 }} %</p>
                                    </div>
                                </div>
                                <div class="grid" style="background-color: rgba(254, 176, 25, 0.85)">
                                    <div class="col-6">
                                        <h4>TOTAL TAGIHAN</h4>
                                    </div>
                                    <div class="col-4" align="right">
                                        <p>{{ $formats(totals).format() }}</p>
                                    </div>
                                    <div class="col-2" align="right">
                                        <p>{{ totals ? Math.floor((totals / totals) * 100) : 0 }} %</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="grid mb-0">
                                    <div class="col-6 text-center"><h5>Grafik Rekap Tagihan</h5></div>
                                </div>
                                <div class="grid mt-0">
                                    <div class="col-6">
                                        <center>
                                            <donut :rekap="rekap" />
                                        </center>
                                    </div>
                                    <!-- <div class="col-2">
                                        <Button class="p-button-success" @click="getRekap()">Cari</Button>
                                    </div>
                                    <div class="col-2">
                                        <Button class="p-button-danger p-fluid" @click="reset()">Reset</Button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="grid">
                        <div class="col ml-2">
                            <div class="card text-center"><h5>GRAFIK REKAP TAGIHAN</h5></div>
                        </div>
                    </div>
                    <div class="grid" v-if="rekap.length">
                        <div class="col">
                            <div class="card">
                                <chart :rekap="rekap" :newChart="newChart" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import chart from '@/components/chart.vue';
import donut from '@/components/donut.vue';
export default {
    data() {
        return {
            prodi: [],
            rekap: [],
            newChart: false,
            totals: 0,
        };
    },
    components: {
        chart,
        donut,
    },
    setup() {
        // const rekap = reactive([]);
        const tahun = reactive([]);
        // const prodi = reactive([]);
        const semester = reactive([
            {
                value: 'GANJIL',
                text: 'GANJIL',
            },
            {
                value: 'GENAP',
                text: 'GENAP',
            },
        ]);
        const data = reactive({
            tahun_kb: null,
            semester: null,
            prodi_id: null,
        });

        return {
            // rekap,
            tahun,
            data,
            semester,
            // prodi,
        };
    },
    watch: {
        semester_aktif: function (newV, oldV) {
            if (newV != oldV) {
                if (newV) {
                    this.data.tahun_kb = newV.kode_tahun_ajaran_feeder;
                    this.data.semester = newV.nama_semester.includes('Ganjil') ? 'GANJIL' : 'GENAP';
                    this.getRekap();
                } else {
                    this.getRekap();
                }
            }
        },
    },
    computed: {
        terbayar() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.terbayar;
            });
            return this.rekap.length ? x : 0;
        },
        total() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.tagihan;
                this.totals = x;
            });
            return this.rekap.length ? x : 0;
        },
        piutang() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.tagihan - el.terbayar;
            });
            return this.rekap.length ? x : 0;
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        semester_aktif() {
            return this.$store.state.billing.semester_aktif ? this.$store.state.billing.semester_aktif : null;
        },
    },
    mounted() {
        this.getData();
        this.getProdi();
        if (this.semester_aktif) {
                    this.data.tahun_kb = this.semester_aktif.kode_tahun_ajaran_feeder;
                    this.data.semester = this.semester_aktif.nama_semester.includes('Ganjil') ? 'GANJIL' : 'GENAP';
                    this.getRekap();
                } else {
                    this.getRekap();
                }
    },
    methods: {
        async getData() {
            let vm = this;
            try {
                for (let i = 2022; i < 2035; i++) {
                    vm.tahun.push({ value: i, text: i });
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getProdi() {
            let vm = this;
            try {
                let prodi = await vm.$axiosbilling('/prodi/listHalaman');

                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.kode_prodi + ' ' + item.nama_prodi };
                });
            } catch (error) {
                console.log(error);
            }
        },
        getNewRekap() {
            let vm = this;
            vm.rekap = [];
            vm.totals = 0;
            vm.getRekap();
        },
        getRekap() {
            let vm = this;
            vm.newChart = false;
            try {
                vm.$axiosbilling
                    .post('detailsTagihanStudi/rekapTagihan', vm.data)
                    .then((res) => {
                        console.log(res, 'rekap');
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.totals = res.data.data[0].Totaltagihan;
                                vm.rekap = res.data.data[0].data;
                                this.newChart = true;
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        persen(x) {
            return x / this.tagihan;
        },
        reset() {
            this.data.tahun_kb = null;
            this.data.semester = null;
            this.data.prodi_id = null;
            this.getRekap();
        },
    },
};
</script>
<style scoped>
.container-fluid {
    min-height: 100vh;
}
.grid {
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn {
    width: 130px;
}
</style>
